







































































































































































import Vue from 'vue';
import * as Api from '@/api/role';
import moment from 'moment';
const columns = [
  {
    title: '角色名称',
    dataIndex: 'roleName',
    key: 'roleName',
  },
  {
    title: '创建人',
    dataIndex: 'recUserName',
    key: 'recUserName',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'recDate',
    key: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '备注',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: '180px',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '状态',
    dataIndex: 'recStatus',
    key: 'recStatus',
    align: 'center',
    scopedSlots: { customRender: 'recStatus' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default Vue.extend({
  data() {
    return {
      tableLoading: false,
      formState: {
        recStatus: 'A',
        roleName: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource: [],
      columns,
      visible: false,
      voidVisible: false,
      voidData: '',
      deleteVisible: false,
      deleteData: '',
      enableVisible: false,
      enableData: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      applyTime: [],
      loading: false,
      form: {
        roleName: '',
        description: '',
      },
      rules: {
        roleName: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 20,
            message: '角色名称应不超过20个字符',
            trigger: 'blur',
          },
        ],
        description: [
          {
            min: 0,
            max: 85,
            message: '角色备注不超过85个字符',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.getRoleManageList();
  },
  methods: {
    moment,
    getRoleManageList() {
      this.tableLoading = true;
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        queryStatus: this.formState.recStatus,
        queryName: this.formState.roleName,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
      };
      Api.pageByPlatform(param)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getRoleManageList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getRoleManageList();
    },
    /**
     * @description: 账号移除弹框按钮
     * @param {*}
     * @return {*}
     */
    deleteOpen(record) {
      this.deleteData = record.roleCode;
      this.deleteVisible = true;
    },
    /**
     * @description: 账号移除弹窗确认按钮
     * @param {*}
     * @return {*}
     */
    Delete() {
      Api.agenciesRoles({
        recStatus: 'D',
        roleCode: this.deleteData,
      }).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('账号删除成功');
          this.deleteVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error(data.message);
        }
      });
    },
    /**
     * @description: 账号置为失效弹框打开按钮
     * @param {*}
     * @return {*}
     */
    voidOpen(record) {
      this.voidData = record.roleCode;
      this.voidVisible = true;
    },
    /**
     * @description: 账号置为失效弹窗确认按钮
     * @param {*}
     * @return {*}
     */
    forVoid() {
      const params = [
        {
          recStatus: 'I',
          roleCode: this.voidData,
        },
      ];
      Api.disable(params).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('置为失效成功');
          this.voidVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error(data.message);
        }
      });
    },
    /**
     * @description: 账号置为失效弹框打开按钮
     * @param {*}
     * @return {*}
     */
    enable(record) {
      this.enableData = record.roleCode;
      this.enableVisible = true;
    },
    /**
     * @description: 账号启用按钮
     * @param {*}
     * @return {*}
     */
    enableOpen() {
      const params = [{ recStatus: 'A', roleCode: this.enableData }];
      Api.enable(params).then((res) => {
        if (res.status === 200) {
          this.$message.success('账号启用成功');
          this.enableVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 添加平台角色弹框打开按钮
     * @param {*}
     * @return {*}
     */
    showModal() {
      this.form.roleName = '';
      this.form.description = '';
      this.visible = true;
    },
    /**
     * @description: 添加平台角色弹框确认提交按钮
     * @param {*}
     * @return {*}
     */
    handleOk() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.loading = true;
          Api.platform(this.form)
            .then((res) => {
              if (res.status === 200) {
                this.$message.success('平台角色新增成功！');
                this.loading = false;
                this.visible = false;
                this.handleSearchSubmit();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.visible = false;
    },
    /**
     * @description: 添加平台角色弹框取消按钮
     * @param {*}
     * @return {*}
     */
    handleCancel() {
      console.log('Clicked cancel button');
      this.visible = false;
    },
  },
});
