/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2021-12-06 14:54:47
 * @LastEditors: wuxinxin
 * @LastEditTime: 2021-12-07 18:39:26
 */
import http from '@/utils/http';

//  平台角色列表查询
export function pageByPlatform(formdata: any): any {
  return http.get(`/agency-resource/1/agenciesRoles/pageByPlatform`, formdata);
}
//  平台角色新增
export function platform(formdata: any): any {
  return http.post(`/agency-resource/1/agenciesRoles/platform`, formdata);
}
//  平台角色置为失效
export function disable(formdata: any): any {
  return http.put(`/agency-resource/1/agenciesRoles/disable`, formdata);
}
//  平台角色启用
export function enable(formdata: any): any {
  return http.put(`/agency-resource/1/agenciesRoles/enable`, formdata);
}
//  平台角色移除
export function agenciesRoles(formdata: any): any {
  return http.delete(`/agency-resource/1/agenciesRoles`, formdata);
}
